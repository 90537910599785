import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/sezer/Documents/Projects/talepnet-landing/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Hint } from '@nejcm/docz-theme-extended';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h2>
    <h2 {...{
      "id": "talepnet"
    }}>{`TalepNET`}</h2>
    <p>{`Satın alma süreçlerinizi kurumsal bir yapıda işletebileceğiniz bir sistemdir. Talep yapan kullanıcılar, depo, satın alma, yönetici onayları, tesellüm ve
faturalama işlemleri ile bütünsel bir çözüm sağlar. Aynı zamanda online teklif ve sipariş fonksiyonları ile tedarikçi operasyonlarını hızlandıracak çok sayıda
özelliği içermektedir.`}</p>
    <p>{`TalepNET'in önemli fonksiyonlarından birisi online tedarikçi işlemleridir. Tedarikçilere tek tıkla teklif ve sipariş gönderir, gelen teklif ve siparişleri
otomatik olarak satın alma sürecinize dahil edersiniz. Eğer aynı zamanda tedarikçi olarak da sistemde yer almak istiyorsanız,
`}<a parentName="p" {...{
        "href": "https://portal.talepnet.com"
      }}>{`TalepNET Portal`}</a>{` sayfasından tedarikçi hesabınızı aktifleştirebilirsiniz. Böylece diğer satın almacılar da firmanızın sunduğu ürün ve hizmetler için size
teklif talebi ve sipariş gönderebilirler. `}</p>
    <p>{`Temel olarak TalepNET satın almacı, `}<a parentName="p" {...{
        "href": "https://portal.talepnet.com"
      }}>{`TalepNET Portal`}</a>{` ise tedarikçi olarak operasyonların yönetilmesi için tasarlanmıştır.`}</p>
    <h2 {...{
      "id": "talepnet-portal"
    }}>{`TalepNET Portal`}</h2>
    <p>{`Eğer tedarikçi iseniz `}<a parentName="p" {...{
        "href": "https://portal.talepnet.com"
      }}>{`TalepNET Portal`}</a>{` sayfası üzerinde gelen teklif ve siparişlerinizi görüntüleyebilirsiniz. Teklif oluşturur ve gönderirsiniz.
Olumlu değerlendirilen teklifleriniz için portal üzerinden yeni sipariş alırsınız. Siparişleri onaylayabilir ve önceki teklif ve siparişlerinizi görüntüleyebilirsiniz.`}</p>
    <h2 {...{
      "id": "ücretsiz-başlayın"
    }}>{`Ücretsiz Başlayın`}</h2>
    <p>{`Hesabınızı hemen ücretsiz oluşturun ve kullanmaya başlayın. TalepNET satın alma yazılımı üzerinde 1 adet kullanıcı ömür boyu ücretsizdir.
Firmanız için çok sayıda kullanıcı ile devam etmek istediğinizde size uygun olan kullanıcı sayısını belirleyerek devam edersiniz.
Ücretsiz üyeliğinizi şimdi `}<a parentName="p" {...{
        "href": "https://app.talepnet.com/pages/auth/signup"
      }}>{`başlatın`}</a>{`.`}</p>
    <h2 {...{
      "id": "fiyatlandırma"
    }}>{`Fiyatlandırma`}</h2>
    <p>{`TalepNET ile hesabınızı oluşturduğunuz kullanıcınız için ömür boyu kullanım ücreti ödemezsiniz. Eğer memnun kalır ve devam etmek isterseniz ihtiyacınız olan
kullanıcı sayısına göre ücretlendirilirsiniz. İstediğiniz zaman kullanıcı sayınızı arttırabilir veya azaltabilirsiniz. Yine isterseniz daha sonra tek
kullanıcılı modele geri dönerek ücret ödemeden devam edebilirsiniz. Kullanıcı sayısına göre fiyatları şimdi `}<a parentName="p" {...{
        "href": "/pricing"
      }}>{`inceleyin`}</a>{`.`}</p>
    <h2 {...{
      "id": "giriş-yapın"
    }}>{`Giriş Yapın`}</h2>
    <p>{`TalepNET kullanıcısıysanız şimdi `}<a parentName="p" {...{
        "href": "https://app.talepnet.com/pages/auth/login"
      }}>{`giriş`}</a>{` yapın.`}</p>
    <h2 {...{
      "id": "parolanızı-unutursanız"
    }}>{`Parolanızı Unutursanız`}</h2>
    <p>{`Eğer TalepNET parolanızı unutursanız hesabınıza ait eposta ile parolanızı yenileyebilirsiniz.
Parolanızı şimdi `}<a parentName="p" {...{
        "href": "https://app.talepnet.com/pages/auth/forgot-password"
      }}>{`yenileyin`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      